<template>
  <v-form ref="form" v-model="pValid" lazy-validation>
    <v-row>
      <v-col cols="12" lg="6" md="6" sm="6" class="new-service-col">
        <HelpFormInputVue
          dark
          v-model="form.name"
          :rules="nameRules"
          label="Question"
          :helpText="'Provide a question of the name'"
          required
        ></HelpFormInputVue>

        <HelpFormInputVue
          dark
          :rows="4"
          v-model="form.description"
          label=" Description"
          :helpText="'Please provide a description if needed'"
          :textarea="true"
          required
        ></HelpFormInputVue>

        <HelpFormInputVue
          dark
          :rows="4"
          v-model="form.goal"
          label="Question Goal"
          :helpText="'Please define a transparent goal of this question. How to understand an answer'"
          :textarea="true"
          required
        ></HelpFormInputVue>

        <FormSection
          underline
          right
          class="mt-0"
          :label="'Tags'"
          :icon="'mdi-tag-multiple-outline'"
          :actions="actions"
          :editable="!!actions.length"
          @action="(action) => $emit('action', action)"
        ></FormSection>

        <SelectorKnowledgeBaseTagsVue
          dark
          autoload
          label="Tags"
          helpText="This is an actual Service Status"
          return-object
          v-model="form.tags"
          multiple
        >
        </SelectorKnowledgeBaseTagsVue>
      </v-col>
    </v-row>
  </v-form>
</template>
    
    
<script>
import { TAGS_BUTTONS } from "../../../../../../views/src/apps/knowledge-base/constants/actions.gh";
import { PermissionsHelper } from "../../../../../helpers/permissions.helper";
import SelectorKnowledgeBaseTagsVue from "../../../../atoms/common/autocompletes/SelectorKnowledgeBaseTags.vue";
import FormSection from "../../../../atoms/common/FormSection.vue";
import HelpFormInputVue from "../../../../atoms/common/inputs/HelpFormInput.vue";

export default {
  props: {
    value: {},
    valid: {
      type: Boolean,
      default: false,
    },
    permissions: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      // Form
      pValid: this.valid,
      form: this.value,

      // ======rules
      nameRules: [(v) => !!v || "Question name is required"],
      descriptionRules: [(v) => !!v || "Description is required"],
    };
  },
  computed: {
    actions: function () {
      return PermissionsHelper.getActions(
        TAGS_BUTTONS.CREATE_NEW,
        this.permissions
      );
    },
  },
  components: {
    HelpFormInputVue,
    SelectorKnowledgeBaseTagsVue,
    FormSection,
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
  watch: {
    value(newVal) {
      this.form = newVal;
    },
    form: {
      handler(newVal) {
        this.$emit("input", newVal);
      },
      deep: true,
    },
    pValid(newVal) {
      this.$emit("onValid", newVal);
    },
  },
};
</script>
    
    
<style lang="scss" scoped>
.new-service-col {
  display: flex;
  flex-direction: column;
  min-width: 200px;
  flex-grow: 1;
  max-width: 100%;
}
</style>